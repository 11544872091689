import React, { useContext, useEffect } from 'react'
import Marquee from "react-fast-marquee";
import {
  IonImg, IonGrid
} from '@ionic/react';
import { LanguageContext } from './LanguageSelect';

function SplashScreen() {
  const { language } = useContext(LanguageContext);
  let splash_screen: HTMLElement | null;
  let splash_inner: HTMLElement | null;
  let tabs_inner: HTMLElement | null;
  let instructions: HTMLElement | null;
  let instructions_bg: HTMLElement | null;
  let ion_title_interval: NodeJS.Timeout;

  useEffect(() => {
    splash_screen = document.querySelector('.splash-screen') as HTMLElement | null;
    splash_inner = document.querySelector('.splash-screen .splash-inner') as HTMLElement | null;
    tabs_inner = document.querySelector('.tabs-inner') as HTMLElement | null;
    instructions = document.querySelector('.instructions') as HTMLElement | null;
    instructions_bg = document.querySelector('.splash-screen .instructions-background-blur') as HTMLElement | null;
    document.body.classList?.add('instructions-open');
    // hide .splash-screen
    setTimeout(() => {
      splash_inner!.style.display = 'none';
      splash_screen!.style.zIndex = '100';
      instructions_bg!.classList?.remove('hide');
      instructions!.classList?.remove('hide');
    }, 3000);
    // continously loop through ion-title inside ion-tabs and add .instruction class to each, wait 3 seconds, then add to next and remove .instruction class from the first one
    const ion_title = document.querySelector('ion-tabs')!.querySelectorAll('ion-title');
    let ion_title_index = 0;
    ion_title_interval = setInterval(() => {
      // remove .instruction class from all ion-title elements if they exist
      if (ion_title.length > 0) {
        for (let i = 0; i < ion_title.length; i++) {
          ion_title[i].classList?.remove('instruction');
        }
      }
      ion_title[ion_title_index].classList?.add('instruction');
      ion_title_index++;
      if (ion_title_index >= ion_title.length) {
        ion_title_index = 0;
      }
    }, 2000);
  }, [document]);

  const closeBtnHandler = () => {
    splash_screen?.classList?.add('hide');
    instructions?.classList?.add('hide');
    instructions_bg?.classList?.add('hide');
    tabs_inner!.style.opacity = '1';
    document?.body?.classList?.remove('instructions-open');
    ion_title_interval && clearInterval(ion_title_interval);
  }

  return (
    <>
      <div className='splash-screen'>
        {/* <IonImg src='assets/img/splash-bg.png' className='splash-bg'></IonImg> */}
        <div className="splash-typo splash-bg">
          <div className="splash-typo-container">
            <Marquee direction={"left"} speed={140}>
              <h1 style={{ marginLeft: '-16%' }}>
                <span className="text-blue">SchoolofSwipe</span>
                <span className="text-green">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"right"} speed={140}>
              <h1 style={{ marginLeft: '-22.5rem' }}>
                <span className="text-purple">SchoolofSwipe</span>
                <span className="text-yellow">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"left"} speed={140}>
              <h1 style={{ marginLeft: '-12.5rem' }}>
                <span className="text-yellow">SchoolofSwipe</span>
                <span className="text-purple">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"right"} speed={140}>
              <h1 style={{ marginLeft: '-32rem' }}>
                <span className="text-green">SchoolofSwipe</span>
                <span className="text-blue">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"left"} speed={140}>
              <h1 style={{ marginLeft: '-14rem' }}>
                <span className="text-blue">SchoolofSwipe</span>
                <span className="text-green">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"right"} speed={140}>
              <h1 style={{ marginLeft: '-30rem' }}>
                <span className="text-yellow">SchoolofSwipe</span>
                <span className="text-purple">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"left"} speed={140}>
              <h1 style={{ marginLeft: '-16rem' }}>
                <span className="text-blue">SchoolofSwipe</span>
                <span className="text-green">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"right"} speed={140}>
              <h1 style={{ marginLeft: '-22.5rem' }}>
                <span className="text-purple">SchoolofSwipe</span>
                <span className="text-yellow">SchoolofSwipe</span>
              </h1>
            </Marquee>
            <Marquee direction={"left"} speed={140}>
              <h1 style={{ marginLeft: '-12.5rem' }}>
                <span className="text-yellow">SchoolofSwipe</span>
                <span className="text-purple">SchoolofSwipe</span>
              </h1>
            </Marquee>
          </div>
        </div>
        <IonGrid className='splash-inner'>
          <IonImg src='assets/img/logo.png' className='logo'></IonImg>
          <h1>{
            language === 'id' ? `Awal yang tepat untuk berkencan dengan cermat.` :
              language === 'vn' ? `Nơi khởi đầu cho những trải nghiệm hẹn hò sáng suốt` :
                language === 'th' ? `เริ่มต้นถูกทาง กับเดทถูกใจ` :
                  `The right start to date smart.`}</h1>
          {/* <IonImg src='assets/img/splash-desc.png' className='desc'></IonImg> */}
        </IonGrid>
        <div className='instructions-background-blur hide'>
        </div>
      </div>
      <div className='instructions hide'>
        <div>
          {/* <IonImg src='assets/img/instructions-box.png' className='instructions-img' style={{ maxWidth: '250px', marginLeft: '22vw', marginTop: '5rem' }}></IonImg> */}
          <div className='instructions-line' style={{ marginTop: '8rem' }}></div>
          <h1 style={{ fontSize: '1.6rem' }}>{
            language === 'id' ? `Geser dan klik\nuntuk memilih topik.` :
              language === 'vn' ? `Trượt và chạm\nđể chọn chủ đề.` :
                language === 'th' ? `เลื่อนดูแล้ว\nเลือกแตะหัวข้อที่สนใจ` :
                  `Slide across and\ntap to choose a topic.`}</h1><br /><br />
          <IonImg className='hide-mobile' src='assets/img/instructions-hand.png' style={{ maxWidth: '5rem', margin: '0 auto' }}></IonImg>
          <IonImg className='hide-desktop' src='assets/img/instructions-hand-2.png' style={{ maxWidth: '6rem', margin: '0 auto' }}></IonImg>
          <h1 style={{ fontSize: '1.6rem' }}>{
            language === 'id' ? `Jelajahi kartu-kartu dan klik\nuntuk membaca lebih lanjut.` :
              language === 'vn' ? `Lướt qua các thẻ\nvà chạm để đọc thêm.` :
                language === 'th' ? `เลือกดูการ์ดแต่ละหัวข้อ\nแล้วแตะเพื่ออ่านเพิ่มเติม` :
                  `Browse through the cards\nand tap to read more.`}</h1>
          <button className='close-instructions white-btn' onClick={() => closeBtnHandler()} style={{ zIndex: '105', marginTop: '1rem' }}>{
            language === 'id' ? `Oke, saya mengerti!` :
              language === 'vn' ? `Đã hiểu!` :
                language === 'th' ? `เข้าใจล่ะ !` :
                  `Got it!`}
          </button>
        </div>
      </div>
    </>
  )
}

export default SplashScreen