import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Header } from './components/Header';
import SplashScreen from './components/SplashScreen';
import DownloadBtn from './components/DownloadBtn';
import { useState } from 'react';
import { PdfContext, PdfViewer } from './components/PdfViewer';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import 'font-proxima-nova/style.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

/* Custom css */
import 'animate.css';
import './theme/style.css';
import { LanguageContext } from './components/LanguageSelect';


import { Redirect, Route } from 'react-router-dom';
import Home from './pages/home';
import AppAcademy from './pages/app-academy';
import SafetySyllabus from './pages/safety-syllabus';
import IRLDating from './pages/irl-dating';
import Au from './pages/au';

import { ParallaxProvider } from 'react-scroll-parallax';

setupIonicReact();

function App() {
  const [desktopPdf, setDesktopPdf] = useState('');
  const [mobilePdf, setMobilePdf] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [language, setLanguage] = useState('en');
  const pdf = {
    modalVisible,
    desktopPdf,
    mobilePdf,
    setDesktopPdf,
    setMobilePdf,
    setModalVisible
  };
  const lang = {
    language,
    setLanguage
  };

  return (
    <>
      <ParallaxProvider>
        <LanguageContext.Provider value={lang}>
          <PdfContext.Provider value={pdf}>
            <IonApp>
              <IonReactRouter>
                <IonRouterOutlet>
                  <Route path="/au" component={Au} />
                  <Route>
                    <SplashScreen></SplashScreen>
                    <Header></Header>
                  </Route>
                </IonRouterOutlet>
              </IonReactRouter>
              <DownloadBtn></DownloadBtn>
            </IonApp>
            <PdfViewer />
          </PdfContext.Provider>
        </LanguageContext.Provider>
      </ParallaxProvider>
    </>
  )
}

export default App;
