import { createContext, useContext, useEffect } from "react";
import { IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { useLocation } from 'react-router';
type LanguageContextType = {
    language: string;
    setLanguage: (value: string) => void;
}

export const LanguageContext = createContext({
    language: "en", // default language is English. Options: en, vn, th, id
    setLanguage: () => { },
} as LanguageContextType);

export function LanguageSelect() {
    const location = useLocation();
    const { language, setLanguage } = useContext(LanguageContext);
    const query = new URLSearchParams(location.search);

    // on page load if query has lang param, set language to that value
    // useEffect(() => {
    //     if (query.get('lang')) {
    //         setLanguage(query.get('lang') as string);
    //     }
    // }, [query]);

    return (
        <>
            <IonSelect interface="popover" value={language} onIonChange={(e) => setLanguage(e.detail.value)} style={{ position: 'absolute', right: '1rem', zIndex: 99 }}>
                <IonSelectOption value="en">English</IonSelectOption>
                <IonSelectOption value="id">Bahasa</IonSelectOption>
                <IonSelectOption value="th">Thai</IonSelectOption>
                <IonSelectOption value="vn">Vietnamese</IonSelectOption>
            </IonSelect>

            {/* <select onChange={(e) => setLanguage(e.target.value)}>
                <option value="en">English</option>
                <option value="vn">Vietnamese</option>
                <option value="th">Thai</option>
                <option value="id">Indonesian</option>
            </select> */}
        </>
    );
}